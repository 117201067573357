var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-4 pt-0 text-center",
              attrs: { cols: "12", xs: "12", md: "12" }
            },
            [
              _c("v-label", [
                _vm._v(
                  _vm._s(_vm.$t("forms.ParentCompanyForm.background_url.label"))
                )
              ]),
              _c("div", [
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("forms.ParentCompanyForm.background_url.hint")
                    )
                  )
                ])
              ]),
              _c("ImageInput", {
                ref: "background_field",
                staticClass: "mt-6 mb-4",
                attrs: {
                  previewMaxHeight: _vm.$vuetify.breakpoint.mdAndUp
                    ? "300px"
                    : "160px",
                  previewMaxWidth: _vm.$vuetify.breakpoint.mdAndUp
                    ? "300px"
                    : "160px",
                  postUpload: _vm.updateCompanyJson("background_url"),
                  type: "update-company-background",
                  required: ""
                },
                model: {
                  value: _vm.value.json.background_url,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "background_url", $$v)
                  },
                  expression: "value.json.background_url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-6 pb-md-6 px-6",
              attrs: { cols: "12", xs: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "font_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t("forms.ParentCompanyForm.font_color.label"),
                  rules: _vm.rules
                },
                on: { input: _vm.fontColorChanged },
                model: {
                  value: _vm.value.json.font_color,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "font_color", $$v)
                  },
                  expression: "value.json.font_color"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-12 pt-md-6 pb-md-6 px-6",
              attrs: { cols: "12", xs: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "primary_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t("forms.ParentCompanyForm.primary_color.label"),
                  rules: _vm.rules
                },
                on: { input: _vm.primaryColorChanged },
                model: {
                  value: _vm.value.json.primary_color,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "primary_color", $$v)
                  },
                  expression: "value.json.primary_color"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-12 pt-md-6 pb-6 px-6",
              attrs: { cols: "12", xs: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "background_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t(
                    "forms.ParentCompanyForm.background_color.label"
                  ),
                  rules: _vm.rules
                },
                on: { input: _vm.backgroundColorChanged },
                model: {
                  value: _vm.value.json.background_color,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "background_color", $$v)
                  },
                  expression: "value.json.background_color"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }