var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0 pb-6", attrs: { cols: "12", xs: "12" } },
            [
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.legal.custom_terms_enable.label"
                  )
                },
                model: {
                  value: _vm.is_custom_terms_enabled,
                  callback: function($$v) {
                    _vm.is_custom_terms_enabled = $$v
                  },
                  expression: "is_custom_terms_enabled"
                }
              }),
              _vm.is_custom_terms_enabled
                ? _c("div", [
                    _c("div", { staticClass: "mb-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "mr-0 mr-lg-4 primary--text",
                          attrs: { for: "custom_terms_html_field" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "forms.ParentCompanyForm.legal.custom_terms_html.label"
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      [
                        _c("TiptapVuetify", {
                          ref: "custom_terms_html_field",
                          staticClass: "mt-2 mt-lg-0 w-100",
                          attrs: {
                            value: _vm.value.json.legal.custom_terms_html,
                            extensions: _vm.tiptapExtensions,
                            "card-props": { flat: true, outlined: true },
                            "editor-props": {},
                            "elevation-0": ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.onInput("custom_terms_html", $event)
                            }
                          }
                        }),
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "forms.ParentCompanyForm.legal.custom_terms_html.hint"
                              )
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          !_vm.is_custom_terms_enabled
            ? _c(
                "v-col",
                {
                  staticClass: "pt-0 pb-6",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-lg-row align-lg-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mr-0 mr-lg-4 primary--text",
                          attrs: { for: "privacy_policy_url_field" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "forms.ParentCompanyForm.legal.privacy_policy_url.label"
                              )
                            )
                          )
                        ]
                      ),
                      _c("FileInput", {
                        ref: "privacy_policy_url_field",
                        staticClass: "mt-2 mt-lg-0",
                        attrs: {
                          value: _vm.value.json.legal.privacy_policy_url,
                          postUpload: _vm.updateCompanyJson(
                            "privacy_policy_url"
                          ),
                          type: "update-company-privacy-policy",
                          maxSizeMB: 4
                        },
                        on: {
                          input: function($event) {
                            return _vm.onInput("privacy_policy_url", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          !_vm.is_custom_terms_enabled
            ? _c(
                "v-col",
                {
                  staticClass: "pt-4 pb-6 pt-md-0",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-lg-row align-lg-center"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mr-0 mr-lg-4 primary--text",
                          attrs: { for: "legal_terms_url_field" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "forms.ParentCompanyForm.legal.terms_url.label"
                              )
                            )
                          )
                        ]
                      ),
                      _c("FileInput", {
                        ref: "legal_terms_url_field",
                        staticClass: "mt-2 mt-lg-0",
                        attrs: {
                          postUpload: _vm.updateCompanyJson("terms_url"),
                          value: _vm.value.json.legal.terms_url,
                          type: "update-company-terms",
                          maxSizeMB: 4
                        },
                        on: {
                          input: function($event) {
                            return _vm.onInput("terms_url", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-0 pb-6",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column flex-lg-row align-lg-center"
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "mr-0 mr-lg-4 primary--text",
                      attrs: { for: "cookie_policy_url_field" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "forms.ParentCompanyForm.legal.cookie_policy_url.label"
                          )
                        )
                      )
                    ]
                  ),
                  _c("FileInput", {
                    ref: "cookie_policy_url_field",
                    staticClass: "mt-2 mt-lg-0",
                    attrs: {
                      value: _vm.value.json.legal.cookie_policy_url,
                      postUpload: _vm.updateCompanyJson("cookie_policy_url"),
                      type: "update-company-cookie-policy",
                      maxSizeMB: 4
                    },
                    on: {
                      input: function($event) {
                        return _vm.onInput("cookie_policy_url", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }