<template>
    <v-container fluid class="mb-2 pt-0">
        <span class="pl-5 pl-md-12 section-title">{{ $t('views.companies.parent.Personalize.title') }}</span>

        <div class="mb-2 pt-4 px-2 px-md-10">
            <PersonalizationCard
                v-for="(item, index) in computedItems"
                :key="index"
                :ref="item.ref"
                :title="item.title"
                class="mb-2"
            >
                <template #content>
                    <component :is="item.component" @update-is-completed="value => handleUpdateIsCompleted(item.ref, value)" />
                </template>
            </PersonalizationCard>
        </div>
    </v-container>
</template>

<script>
import RegistrationMessageContent from '@/views/companies/parent/content/RegistrationMessage'
import LandingPageLayoutContent from '@/views/companies/parent/content/LandingPageLayout'
import EmailsCustomization from '@/views/companies/parent/content/EmailsCustomization'
import ProgramSettingsContent from '@/views/companies/parent/content/ProgramSettings'
import FrontendLayoutContent from '@/views/companies/parent/content/FrontendLayout'
import GeneralLayoutContent from '@/views/companies/parent/content/GeneralLayout'
import PersonalizationCard from '@/components/cards/CompanyPersonalizationCard'
import GeneralContent from '@/views/companies/parent/content/General'
import FooterContent from '@/views/companies/parent/content/Footer'
import LevelsContent from '@/views/companies/parent/content/Levels'
import TermsContent from '@/views/companies/parent/content/Terms'
import FaqContent from '@/views/companies/parent/content/Faq'
import Company from '@/models/Company'
import { mapGetters } from 'vuex'

export default {
    name: 'PersonalizeCompany',
    components: { PersonalizationCard, },
    methods: {
        handleUpdateIsCompleted(ref, value) {
            // Atualiza a empresa selecionada (a empresa selecionada sempre é a matriz, dado que essa view só pode ser acessada com a matriz selecionada)
            this.$store.dispatch('company/setSelectedCompany', new Company(this.$lodash.cloneDeep(this.company)))

            let component = this.$refs[ref]
            if (component && component[0])
                component[0].setIsComplete(value)
        },
    },
    computed: {
        ...mapGetters({
            company: 'company/state',
        }),

        computedItems() {
            return [
                { ref: 'general-card',              title: this.$t('views.companies.parent.Personalize.cards_titles.general'),              component: GeneralContent             },
                { ref: 'levels-card',               title: this.$t('views.companies.parent.Personalize.cards_titles.levels'),               component: LevelsContent              },
                { ref: 'program-settings-card',     title: this.$t('views.companies.parent.Personalize.cards_titles.program_settings'),     component: ProgramSettingsContent     },
                { ref: 'registration-message-card', title: this.$t('views.companies.parent.Personalize.cards_titles.registration_message'), component: RegistrationMessageContent },
                { ref: 'general-layout-card',       title: this.$t('views.companies.parent.Personalize.cards_titles.general_layout'),       component: GeneralLayoutContent       },
                { ref: 'frontend-layout-card',      title: this.$t('views.companies.parent.Personalize.cards_titles.frontend_layout'),      component: FrontendLayoutContent      },
                { ref: 'landing-page-layout-card',  title: this.$t('views.companies.parent.Personalize.cards_titles.landing_page_layout'),  component: LandingPageLayoutContent   },
                { ref: 'faq-card',                  title: this.$t('views.companies.parent.Personalize.cards_titles.faq'),                  component: FaqContent                 },
                { ref: 'terms-card',                title: this.$t('views.companies.parent.Personalize.cards_titles.terms'),                component: TermsContent               },
                { ref: 'footer-card',               title: this.$t('views.companies.parent.Personalize.cards_titles.footer'),               component: FooterContent              },
                { ref: 'emails-customization',      title: this.$t('views.companies.parent.Personalize.cards_titles.emails_customization'), component: EmailsCustomization        },
            ]
        },
    },
}
</script>