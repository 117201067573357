var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cp-text-field",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          attrs: {
            type: "number",
            value: _vm.localValue,
            rules: _vm.computedRules
          },
          on: { input: _vm.onInput }
        },
        "cp-text-field",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }