<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <p class="grey--text">
            {{ $t('views.companies.parent.Infos.custom_emails.description') }}
        </p>
        <v-checkbox
            ref="email_customization_enabled_field"
            v-model="value.json.custom_emails.enabled"
            :label="$t('forms.ParentCompanyForm.emails_customization.enabled.label')"
        ></v-checkbox>

        <div v-if="value.json.custom_emails.enabled" class="mb-6">
            <div
                v-for="(emailField, index) in emailFields"
                :key="index"
            >
                <v-card-subtitle class="pa-0 pt-6 primary--text">
                    {{ $t(`views.companies.parent.Infos.custom_emails.${emailField}.title`) }}
                </v-card-subtitle>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <p class="grey--text">
                            {{ $t(`views.companies.parent.Infos.custom_emails.${emailField}.description`) }}
                        </p>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            :ref="`emails_customization_${emailField}_title_field`"
                            :label="$t(`forms.ParentCompanyForm.emails_customization.${emailField}.title.label`)"
                            type="text"
                            v-model="value.json.custom_emails[emailField].title"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <div class="mb-3">
                            <label
                                :for="`emails_customization_${emailField}_body_field`"
                                class="mr-0 mr-lg-4 caption"
                            >
                                <b>{{ $t(`forms.ParentCompanyForm.emails_customization.${emailField}.body.label`) }}</b>
                            </label>
                        </div>
                        <div>
                            <TiptapVuetify
                                :ref="`emails_customization_${emailField}_body_field`"
                                class="mt-2 mt-lg-0 w-100"
                                :value="value.json.custom_emails[emailField].body"
                                @input="onInput(`custom_emails.${emailField}.body`, $event)"
                                :extensions="tiptapExtensions"
                                :card-props="{ flat: true, outlined: true }"
                                :editor-props="{}"
                                elevation-0
                            />
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-divider></v-divider>
        </div>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'
import Company from '@/models/Company'
import _ from 'lodash'
import {
    TiptapVuetify,
    Link,
    Bold,
    Italic,
    Blockquote,
    Heading,
    ListItem,
    BulletList,
    OrderedList,
    HorizontalRule,
    History,
    Underline,
    Strike,
} from 'tiptap-vuetify'

const TargetBlankLink = [Link, {
    options: {
        target: '_blank',
    },
}]

const Headings = [Heading, {
    options: {
        levels: [1, 2, 3],
    },
}]

export default {
    name: 'EmailsCustomizationForm',
    mixins: [FormMixin(Company, '')],
    components: {
        TiptapVuetify,
    },
    data: () => ({
        tiptapExtensions: [
            History,
            Headings,
            TargetBlankLink,
            Bold,
            Italic,
            Underline,
            Strike,
            Blockquote,
            ListItem,
            BulletList,
            OrderedList,
            HorizontalRule,
        ],
        emailFields: [
            'successful_transaction',
            'promotion_triggered',
            'customer_level_updated_simple',
            'customer_level_updated_renovation',
            'customer_level_updated_setup',
            'customer_level_updated_period_update',
            'customer_level_updated_system_update',
            'vouchers_received',
        ],
    }),
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        onInput(attributeName, value) {
            _.set(this.value.json, attributeName, value)
        },
    },
}
</script>
