<template>
    <cp-text-field
        ref="input"
        type="number"
        :value="localValue"
        @input="onInput"
        :rules="computedRules"
        v-bind="attrs"
        v-on="listeners"
    />
        <!-- v-money="moneyConfig" -->
</template>

<script>
// import { VMoney } from '@/utils/v-money'
import { numeric, gte, isnil } from '@/utils/validation'

export default {
    name: 'AmountInput',
    inheritAttrs: false,
    // directives: {
    //     money: VMoney,
    // },
    props: {
        precision: {
            type: Number,
            default: () => 0,
        },
        value: [ String, Number ],
        suffix: String,
    },
    data: vm => ({
        localValue   : vm.value,
        originalRules: [],
        thisRules    : [ v => v !== '' ? numeric.and(gte(0)).or(isnil)(v) : true ],
    }),
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        onInput(v) {
            this.$emit('input', v !== '' && !this.$lodash.isNil(v) ? this.$lodash.toNumber(v) : null)
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, rules, ...attrs } = this.$attrs
            this.originalRules = rules
            return { ...attrs }
        },

        computedRules() {
            return this.thisRules.concat(this.originalRules).filter(r => !!r)
        },

        // moneyConfig() {
        //     return {
        //         decimal: ',',
        //         thousands: '.',
        //         prefix: '',
        //         suffix: this.suffix,
        //         precision: this.precision,
        //         masked: false,
        //     }
        // },
    },
}
</script>
