<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <!-- Seção registro -->
        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.frontend.register.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <cp-text-field   
                    ref="opt_in_marketing_label_field"
                    :label="$t('forms.ParentCompanyForm.frontend.register.opt_in_marketing.label.label')"
                    type="text"
                    v-model="value.json.frontend.register.opt_in_marketing.label"
                    :rules="rules"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import FormMixin         from '@/mixins/FormMixin'
import Company           from '@/models/Company'
import { empty, length } from '@/utils/validation'

export default {
    name: 'FrontendLayoutForm',
    mixins: [ FormMixin(Company, 'opt_in_marketing_label_field') ],
    data: vm => {
        return {
            rules: [ empty.or(length(1, 191)) ]
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    font-size: 14px;
    font-weight: normal;
}
</style>