<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="8" lg="9" class="py-0">
                <cp-text-field   
                    ref="company_name_field"
                    :label="$t('forms.ParentCompanyForm.company_name.label')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="3" class="py-0">
                <DocumentInput   
                    ref="document_field"
                    :label="$t('forms.ParentCompanyForm.document.label')"
                    :placeholder="$t('forms.ParentCompanyForm.document.placeholder')"
                    required type="text"
                    :swapButton="false"
                    :useCNPJ="true"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>
    </v-form>
</template>

<script>
import DocumentInput from '@/shared/components/inputs/DocumentInput'
import FormMixin     from '@/mixins/FormMixin'
import Company       from '@/models/Company'

export default {
    name: 'GeneralForm',
    mixins: [ FormMixin(Company, 'company_name_field') ],
    components: { DocumentInput },
    methods: {
        async validate() {
            return await this.value.validate()
        },
    },
}
</script>