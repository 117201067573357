<template>
    <div class="pa-4 px-6">
        <v-alert type="error" v-if="notAuthorized">
            {{ $t('forms.not_authorized') }}
        </v-alert>
        <v-alert type="error" v-if="networkError">
            {{ $t('globals.network_error') }}
        </v-alert>

        <LandingPageLayoutForm
            ref="form"
            v-model="localCompany"
            @submit="submit"
            :disabled="loading"
            :isFormValid.sync="isFormValid"
        >
            <template #submit>
                <div class="d-flex justify-end">
                    <v-btn type="submit" color="primary" depressed class="action-button" :loading="loading" :disabled="!isFormValid">
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </template>
        </LandingPageLayoutForm>
    </div>
</template>

<script>
import LandingPageLayoutForm from '@/components/forms/LandingPageLayoutForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Company from '@/models/Company'
import { mapGetters } from 'vuex'

export default {
    name: 'LandingPageLayout',
    components: {
        LandingPageLayoutForm,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        localCompany: new Company,

        loading: false,
        notAuthorized: false,
        isFormValid: false,
        networkError: false
    }),
    created() {
        this.cloneCompany()
    },
    mounted() {
        this.$emit('update-is-completed', this.$refs.form.validate())
    },
    methods: {
        cloneCompany() {
            this.localCompany = new Company(this.$lodash.cloneDeep(this.company))

            // Para evitar que todos os atributos do json sejam atualizados juntos com os desse form, é
            // é preciso especificar aqui todos os atributos utilizados nesse form, e somente eles
            this.localCompany.json = {
                home               : this.$lodash.get(this.localCompany.json, 'home',                {}),
                how_it_works       : this.$lodash.get(this.localCompany.json, 'how_it_works',        {}),
                description        : this.$lodash.get(this.localCompany.json, 'description',         {}),
                footer             : this.$lodash.get(this.localCompany.json, 'footer',              {}),
                landing_page_colors: this.$lodash.get(this.localCompany.json, 'landing_page_colors', {}),
            }
        },
        async submit(company) {
            this.networkError  = false
            this.notAuthorized = false

            // Tenta subir os arquivos, se existentes
            this.loading = true
            await this.$refs.form.triggerFileUploads()
                .catch(this.preErrorHandler)

            let res = await company.update(['json'])
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            // Atualiza a empresa
            await this.$store.dispatch('company/fetchData')
            this.cloneCompany()
            this.$emit('update-is-completed', this.isFormValid)

            this.$bus.$emit('message', this.$t('views.companies.parent.Personalize.alerts.success'), 'success')
        },

        preErrorHandler(e) {
            this.loading = false

            if (e.message == 'Network Error')
                this.networkError = true

            let status = this.$lodash.get(e, 'response.status')

            if (status == 403) {
                this.notAuthorized = true
            }

            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            company: 'company/state',
        }),
    },
}
</script>