var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4 px-6" },
    [
      _vm.notAuthorized
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("forms.not_authorized")) + " ")
          ])
        : _vm._e(),
      _vm.networkError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t("globals.network_error")) + " ")
          ])
        : _vm._e(),
      _c("RegistrationMessageForm", {
        ref: "form",
        attrs: { disabled: _vm.loading, isFormValid: _vm.isFormValid },
        on: {
          submit: _vm.submit,
          "update:isFormValid": function($event) {
            _vm.isFormValid = $event
          },
          "update:is-form-valid": function($event) {
            _vm.isFormValid = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "submit",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "action-button",
                        attrs: {
                          type: "submit",
                          color: "primary",
                          depressed: "",
                          loading: _vm.loading,
                          disabled: !_vm.isFormValid
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("actions.save")) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.localCompany,
          callback: function($$v) {
            _vm.localCompany = $$v
          },
          expression: "localCompany"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }