var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xs: "12", sm: "12", md: "12" } },
            [
              _vm.settings.levels_system == _vm.lookbackLevelsSystem
                ? _c("AmountInput", {
                    ref: "period_field",
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.levels.update_lookback.label"
                      ),
                      hint: _vm.$t(
                        "forms.ParentCompanyForm.levels.update_lookback.hint"
                      ),
                      rules: _vm.rules
                    },
                    model: {
                      value: _vm.value.levels.update_lookback,
                      callback: function($$v) {
                        _vm.$set(_vm.value.levels, "update_lookback", $$v)
                      },
                      expression: "value.levels.update_lookback"
                    }
                  })
                : _vm.settings.levels_system == _vm.renovationLevelsSystem
                ? _c("AmountInput", {
                    ref: "period_field",
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.levels.renovation_interval.label"
                      ),
                      hint: _vm.$t(
                        "forms.ParentCompanyForm.levels.renovation_interval.hint"
                      ),
                      rules: _vm.rules
                    },
                    model: {
                      value: _vm.value.levels.renovation_interval,
                      callback: function($$v) {
                        _vm.$set(_vm.value.levels, "renovation_interval", $$v)
                      },
                      expression: "value.levels.renovation_interval"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }