<template>
    <div class="pa-4 px-6">
        <v-alert type="error" v-if="notAuthorized">
            {{ $t('forms.not_authorized') }}
        </v-alert>
        <v-alert type="error" v-if="networkError">
            {{ $t('globals.network_error') }}
        </v-alert>

        <ProgramSettingsForm
            ref="form"
            v-model="localCompany"
            @submit="submit"
            :disabled="loading"
            :isFormValid.sync="isFormValid"
        >
            <template #submit>
                <div class="d-flex justify-end">
                    <v-btn type="submit" color="primary" depressed class="action-button" :loading="loading" :disabled="!isFormValid">
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
            </template>
        </ProgramSettingsForm>
    </div>
</template>

<script>
import ProgramSettingsForm from '@/components/forms/ProgramSettingsForm'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Company from '@/models/Company'
import { mapGetters } from 'vuex'

export default {
    name: 'ProgramSettings',
    components: {
        ProgramSettingsForm,
    },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        localCompany: new Company,

        loading      : false,
        notAuthorized: false,
        isFormValid  : false,
        networkError : false,
    }),
    created() {
        this.cloneCompany()
    },
    mounted() {
        this.$emit('update-is-completed', this.$refs.form.validate())
    },
    methods: {
        cloneCompany() {
            this.localCompany = new Company(this.$lodash.cloneDeep(this.company))

            // Para evitar que todos os atributos do json sejam atualizados juntos com os desse form, é
            // é preciso especificar aqui todos os atributos utilizados nesse form, e somente eles
            this.localCompany.json = {
                official_program_name:        this.$lodash.get(this.localCompany.json, 'official_program_name',        ''                ),
                program_name:                 this.$lodash.get(this.localCompany.json, 'program_name',                 ''                ),
                display_mode:                 this.$lodash.get(this.localCompany.json, 'display_mode',                 ''                ),
                hide_cashback:                this.$lodash.get(this.localCompany.json, 'hide_cashback',                false             ),
                disable_cashback:             this.$lodash.get(this.localCompany.json, 'disable_cashback',             false             ),
                hide_points:                  this.$lodash.get(this.localCompany.json, 'hide_points',                  false             ),
                disable_action_notifications: this.$lodash.get(this.localCompany.json, 'disable_action_notifications', false             ),
                favicon_url:                  this.$lodash.get(this.localCompany.json, 'favicon_url',                  ''                ),
                logo_url:                     this.$lodash.get(this.localCompany.json, 'logo_url',                     ''                ),
                frontend_domain:              this.$lodash.get(this.localCompany.json, 'frontend_domain',              ''                ),
                landing_page_domain:          this.$lodash.get(this.localCompany.json, 'landing_page_domain',          ''                ),
                admin_domain:                 this.$lodash.get(this.localCompany.json, 'admin_domain',                 ''                ),
                custom_emails:                this.$lodash.get(this.localCompany.json, 'custom_emails',                { enabled: false }),
            }
        },
        async submit(company) {
            this.networkError  = false
            this.notAuthorized = false

            // Tenta subir os arquivos, se existentes
            this.loading = true
            await this.$refs.form.triggerFileUploads()
                .catch(this.preErrorHandler)

            let res = await company.update(['json'])
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            await this.$store.dispatch('company/fetchData')
            this.cloneCompany()
            this.$emit('update-is-completed', this.isFormValid)
            
            this.$bus.$emit('message', this.$t('views.companies.parent.Personalize.alerts.success'), 'success')
        },
        preErrorHandler(e) {
            this.loading = false

            if (e.message == 'Network Error')
                this.networkError = true

            let status = this.$lodash.get(e, 'response.status')

            if (status == 403) {
                this.notAuthorized = true
            }

            this.errorHandler(e)
        },
    },
    computed: {
        ...mapGetters({
            company: 'company/state',
        }),
    },
}
</script>