var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "12" }
            },
            [
              _c("cp-text-field", {
                ref: "faq_title_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.faq.title.label"),
                  type: "text",
                  rules: _vm.rules
                },
                model: {
                  value: _vm.value.json.faq.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.faq, "title", $$v)
                  },
                  expression: "value.json.faq.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "pa-0 pt-6 section-title primary--text" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("views.companies.parent.Infos.faq.items.title")) +
              " "
          )
        ]
      ),
      _vm._l(_vm.value.json.faq.items, function(item, index) {
        return _c(
          "v-row",
          { key: index },
          [
            _c(
              "v-col",
              {
                staticClass: "py-0",
                attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
              },
              [
                _c("cp-textarea", {
                  ref: "faq_items_question_" + index + "_field",
                  refInFor: true,
                  attrs: {
                    label: _vm.$t(
                      "forms.ParentCompanyForm.faq.items.question.label",
                      { index: Number(index) + 1 }
                    ),
                    type: "text",
                    rows: "4",
                    rules: _vm.rules
                  },
                  model: {
                    value: _vm.value.json.faq.items[index].question,
                    callback: function($$v) {
                      _vm.$set(_vm.value.json.faq.items[index], "question", $$v)
                    },
                    expression: "value.json.faq.items[index].question"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              {
                staticClass: "py-0",
                attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
              },
              [
                _c("cp-textarea", {
                  ref: "faq_items_answer_" + index + "_field",
                  refInFor: true,
                  attrs: {
                    label: _vm.$t(
                      "forms.ParentCompanyForm.faq.items.answer.label",
                      { index: Number(index) + 1 }
                    ),
                    type: "text",
                    rows: "4",
                    rules: _vm.rules
                  },
                  model: {
                    value: _vm.value.json.faq.items[index].answer,
                    callback: function($$v) {
                      _vm.$set(_vm.value.json.faq.items[index], "answer", $$v)
                    },
                    expression: "value.json.faq.items[index].answer"
                  }
                })
              ],
              1
            ),
            _vm.$vuetify.breakpoint.smAndDown &&
            _vm.value.json.faq.items &&
            _vm.value.json.faq.items.length &&
            Number(index) < _vm.value.json.faq.items.length - 1
              ? _c(
                  "v-col",
                  { staticClass: "pb-6", attrs: { cols: "12" } },
                  [_c("v-divider")],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }