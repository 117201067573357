var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "12" }
            },
            [
              _c("cp-text-field", {
                ref: "home_title_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.home.title.label"),
                  type: "text",
                  rules: _vm.rules.required
                },
                model: {
                  value: _vm.value.json.home.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home, "title", $$v)
                  },
                  expression: "value.json.home.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-title", { staticClass: "pa-0 section-title primary--text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("views.companies.parent.Infos.home.navbar.title")) +
            " "
        )
      ]),
      _c(
        "v-row",
        { staticClass: "justify-center", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pt-6 pb-md-6 px-6",
              attrs: { cols: "12", xs: "12", sm: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "landing_page_navbar_font_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t(
                    "forms.ParentCompanyForm.landing_page.font_color.label"
                  ),
                  rules: _vm.rules.optional
                },
                on: { input: _vm.navbarFontColorChanged },
                model: {
                  value: _vm.value.json.landing_page_colors.nav_font_color,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.landing_page_colors,
                      "nav_font_color",
                      $$v
                    )
                  },
                  expression: "value.json.landing_page_colors.nav_font_color"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-12 pt-md-6 pb-6 px-6",
              attrs: { cols: "12", xs: "12", sm: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "landing_page_background_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t(
                    "forms.ParentCompanyForm.landing_page.background_color.label"
                  ),
                  rules: _vm.rules.optional
                },
                on: { input: _vm.navbarBackgroundColorChanged },
                model: {
                  value:
                    _vm.value.json.landing_page_colors.nav_background_color,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.landing_page_colors,
                      "nav_background_color",
                      $$v
                    )
                  },
                  expression:
                    "value.json.landing_page_colors.nav_background_color"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-title", { staticClass: "pa-0 section-title primary--text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("views.companies.parent.Infos.home.hero.title")) +
            " "
        )
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "home_hero_title_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.title.label"
                  ),
                  type: "text",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.value.json.home.hero.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.hero, "title", $$v)
                  },
                  expression: "value.json.home.hero.title"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "home_hero_subtitle_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.subtitle.label"
                  ),
                  type: "text",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.value.json.home.hero.subtitle,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.hero, "subtitle", $$v)
                  },
                  expression: "value.json.home.hero.subtitle"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-textarea", {
                ref: "home_hero_description_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.description.label"
                  ),
                  type: "text",
                  rows: "4",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.value.json.home.hero.description,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.hero, "description", $$v)
                  },
                  expression: "value.json.home.hero.description"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("v-label", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "forms.ParentCompanyForm.home.hero.background_url.label"
                    )
                  )
                )
              ]),
              _c("div", [
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "forms.ParentCompanyForm.home.hero.background_url.hint"
                      )
                    )
                  )
                ])
              ]),
              _c("ImageInput", {
                ref: "home_hero_background_url_field",
                staticClass: "mt-6 mb-4",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.background_url.label"
                  ),
                  hint: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.background_url.hint"
                  ),
                  placeholder: _vm.$t(
                    "forms.ParentCompanyForm.home.hero.background_url.placeholder"
                  ),
                  previewMaxHeight: "300px",
                  previewMaxWidth: "300px",
                  postUpload: _vm.updateCompanyJson(
                    "json.home.hero.background_url"
                  ),
                  type: "update-company-home-background",
                  rules: _vm.rules.required,
                  required: ""
                },
                model: {
                  value: _vm.value.json.home.hero.background_url,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.hero, "background_url", $$v)
                  },
                  expression: "value.json.home.hero.background_url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-8 pb-0 pt-md-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "div",
                [
                  _c("v-label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("forms.ParentCompanyForm.home.hero.height.label")
                      )
                    )
                  ])
                ],
                1
              ),
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t("forms.ParentCompanyForm.home.hero.height.hint")
                  )
                )
              ]),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: true },
                  model: {
                    value: _vm.value.json.home.hero.height,
                    callback: function($$v) {
                      _vm.$set(_vm.value.json.home.hero, "height", $$v)
                    },
                    expression: "value.json.home.hero.height"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.home.hero.height.options.small"
                      ),
                      value: "small"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.home.hero.height.options.medium"
                      ),
                      value: "medium"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.home.hero.height.options.large"
                      ),
                      value: "large"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.home.hero.height.options.fullheight"
                      ),
                      value: "fullheight"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-title", { staticClass: "pa-0 section-title primary--text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("views.companies.parent.Infos.home.features.title")) +
            " "
        )
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "home_features_title_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.features.title.label"
                  ),
                  type: "text",
                  rules: _vm.rules.required
                },
                model: {
                  value: _vm.value.json.home.features.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.features, "title", $$v)
                  },
                  expression: "value.json.home.features.title"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "home_features_subtitle_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.home.features.subtitle.label"
                  ),
                  type: "text",
                  rules: _vm.rules.required
                },
                model: {
                  value: _vm.value.json.home.features.subtitle,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.home.features, "subtitle", $$v)
                  },
                  expression: "value.json.home.features.subtitle"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "pa-0 pt-6 section-title primary--text" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("views.companies.parent.Infos.how_it_works.hero.title")
              ) +
              " "
          )
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "how_it_works_hero_title_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.hero.title.label"
                  ),
                  type: "text",
                  rules: _vm.rules.required
                },
                model: {
                  value: _vm.value.json.how_it_works.hero.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.how_it_works.hero, "title", $$v)
                  },
                  expression: "value.json.how_it_works.hero.title"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("v-label", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "forms.ParentCompanyForm.how_it_works.hero.background_url.label"
                    )
                  )
                )
              ]),
              _c("div", [
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "forms.ParentCompanyForm.how_it_works.hero.background_url.hint"
                      )
                    )
                  )
                ])
              ]),
              _c("ImageInput", {
                ref: "how_it_works_hero_background_url_field",
                staticClass: "mt-6 mb-4",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.hero.background_url.label"
                  ),
                  hint: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.hero.background_url.hint"
                  ),
                  placeholder: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.hero.background_url.placeholder"
                  ),
                  previewMaxHeight: "300px",
                  previewMaxWidth: "300px",
                  postUpload: _vm.updateCompanyJson(
                    "json.how_it_works.hero.background_url"
                  ),
                  type: "update-company-how-it-works-background",
                  rules: _vm.rules.required,
                  required: ""
                },
                model: {
                  value: _vm.value.json.how_it_works.hero.background_url,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.how_it_works.hero,
                      "background_url",
                      $$v
                    )
                  },
                  expression: "value.json.how_it_works.hero.background_url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-8 pb-0 pt-md-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "div",
                [
                  _c("v-label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "forms.ParentCompanyForm.how_it_works.hero.height.label"
                        )
                      )
                    )
                  ])
                ],
                1
              ),
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "forms.ParentCompanyForm.how_it_works.hero.height.hint"
                    )
                  )
                )
              ]),
              _c(
                "v-radio-group",
                {
                  attrs: { mandatory: true },
                  model: {
                    value: _vm.value.json.how_it_works.hero.height,
                    callback: function($$v) {
                      _vm.$set(_vm.value.json.how_it_works.hero, "height", $$v)
                    },
                    expression: "value.json.how_it_works.hero.height"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.how_it_works.hero.height.options.small"
                      ),
                      value: "small"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.how_it_works.hero.height.options.medium"
                      ),
                      value: "medium"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.how_it_works.hero.height.options.large"
                      ),
                      value: "large"
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "forms.ParentCompanyForm.how_it_works.hero.height.options.fullheight"
                      ),
                      value: "fullheight"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "pa-0 pt-6 section-title primary--text" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "views.companies.parent.Infos.how_it_works.features.title"
                )
              ) +
              " "
          )
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-textarea", {
                ref: "how_it_works_features_description_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.features.description.label"
                  ),
                  type: "text",
                  rows: "4",
                  rules: _vm.rules.required_textarea
                },
                model: {
                  value: _vm.value.json.how_it_works.features.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.how_it_works.features,
                      "description",
                      $$v
                    )
                  },
                  expression: "value.json.how_it_works.features.description"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "justify-center", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pt-6 pb-md-6 px-6",
              attrs: { cols: "12", xs: "12", sm: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "how_it_works_features_font_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.features.font_color.label"
                  ),
                  rules: _vm.rules.optional
                },
                on: { input: _vm.cardFontColorChanged },
                model: {
                  value: _vm.value.json.landing_page_colors.card_font_color,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.landing_page_colors,
                      "card_font_color",
                      $$v
                    )
                  },
                  expression: "value.json.landing_page_colors.card_font_color"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-12 pt-md-6 pb-6 px-6",
              attrs: { cols: "12", xs: "12", sm: "12", md: "4" }
            },
            [
              _c("ColorPickerInput", {
                ref: "how_it_works_features_background_color_field",
                staticClass: "mx-auto",
                attrs: {
                  width: "200px",
                  mode: "hexa",
                  label: _vm.$t(
                    "forms.ParentCompanyForm.how_it_works.features.background_color.label"
                  ),
                  rules: _vm.rules.optional
                },
                on: { input: _vm.cardBackgroundColorChanged },
                model: {
                  value:
                    _vm.value.json.landing_page_colors.card_background_color,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json.landing_page_colors,
                      "card_background_color",
                      $$v
                    )
                  },
                  expression:
                    "value.json.landing_page_colors.card_background_color"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "pa-0 pt-6 section-title primary--text" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("views.companies.parent.Infos.description.title")) +
              " "
          )
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "8" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "description_title_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ParentCompanyForm.description.title.label"
                          ),
                          type: "text",
                          rules: _vm.rules.optional
                        },
                        model: {
                          value: _vm.value.json.description.title,
                          callback: function($$v) {
                            _vm.$set(_vm.value.json.description, "title", $$v)
                          },
                          expression: "value.json.description.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-textarea", {
                        ref: "description_description_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ParentCompanyForm.description.description.label"
                          ),
                          type: "text",
                          rules: _vm.rules.optional_textarea
                        },
                        model: {
                          value: _vm.value.json.description.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.value.json.description,
                              "description",
                              $$v
                            )
                          },
                          expression: "value.json.description.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "4" }
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-6 pb-6 px-6", attrs: { cols: "12" } },
                    [
                      _c("ColorPickerInput", {
                        ref: "description_font_color_field",
                        staticClass: "mx-auto",
                        attrs: {
                          width: "200px",
                          mode: "hexa",
                          label: _vm.$t(
                            "forms.ParentCompanyForm.description.font_color.label"
                          ),
                          rules: _vm.rules.optional
                        },
                        on: { input: _vm.descriptionFontColorChanged },
                        model: {
                          value: _vm.value.json.landing_page_colors.title_color,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.value.json.landing_page_colors,
                              "title_color",
                              $$v
                            )
                          },
                          expression:
                            "value.json.landing_page_colors.title_color"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }