<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12">
                <AmountInput
                    v-if="settings.levels_system == lookbackLevelsSystem"
                    ref="period_field"
                    :label="$t('forms.ParentCompanyForm.levels.update_lookback.label')"
                    :hint="$t('forms.ParentCompanyForm.levels.update_lookback.hint')"
                    v-model="value.levels.update_lookback"
                    :rules="rules"
                />
                <AmountInput
                    v-else-if="settings.levels_system == renovationLevelsSystem"
                    ref="period_field"
                    :label="$t('forms.ParentCompanyForm.levels.renovation_interval.label')"
                    :hint="$t('forms.ParentCompanyForm.levels.renovation_interval.hint')"
                    v-model="value.levels.renovation_interval"
                    :rules="rules"
                />
            </v-col>
        </v-row>
            
        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import AmountInput    from '@/components/inputs/AmountInput'
import FormMixin      from '@/mixins/FormMixin'
import Company        from '@/models/Company'
import constants      from '@/services/constants'
import { mapGetters } from 'vuex'
import { required, positiveInteger } from '@/utils/validation'

export default {
    name: 'LevelsForm',
    mixins: [ FormMixin(Company, 'period_field') ],
    components: { AmountInput },
    data: vm => {
        return {
            rules: [ required.and(positiveInteger(1, 4, 3650)) ],
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
    },
    computed: {
        ...mapGetters({
            settings: 'company/settings',
        }),

        lookbackLevelsSystem() {
            return constants.getConstant('LOOKBACK_LEVELS_SYSTEM')    
        },
        renovationLevelsSystem() {
            return constants.getConstant('RENOVATION_LEVELS_SYSTEM')    
        },
    }
}
</script>