<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="mark_text_field"
                    :label="$t('forms.ParentCompanyForm.footer.mark.text.label')"
                    type="text"
                    v-model="mark.text"
                    :rules="rules.optional"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="mark_url_field"
                    :label="$t('forms.ParentCompanyForm.footer.mark.url.label')"
                    type="text"
                    v-model="mark.url"
                    :rules="rules.optional"
                />
            </v-col>

            <v-col cols="12" class="py-0">
                <cp-text-field   
                    ref="text_field"
                    :label="$t('forms.ParentCompanyForm.footer.text.label')"
                    type="text"
                    v-model="text"
                    :rules="rules.optional"
                />
            </v-col>
        </v-row>

        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.footer.links.title') }}
        </v-card-title>

        <div v-for="(link, i) in links" :key="i" class="pt-3">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                    <cp-text-field   
                        :ref="`link${i}_text_field`"
                        :label="$t('forms.ParentCompanyForm.footer.links.text.label')"
                        type="text"
                        v-model="link.text"
                        :rules="rules.required"
                    />
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                    <cp-text-field   
                        :ref="`link${i}_url_field`"
                        :label="$t('forms.ParentCompanyForm.footer.links.url.label')"
                        type="text"
                        v-model="link.url"
                        :rules="rules.required"
                    />
                </v-col>
            </v-row>

            <v-btn @click="removeLink(i)" text class="background error--text mb-3">{{ $t('forms.ParentCompanyForm.footer.links.remove_link') }}</v-btn>

            <v-divider></v-divider>
        </div>

        <v-row>
            <v-col cols="12" class="">
                <v-btn :disabled="!allowAddLink" @click="addLink" text class="background primary--text">{{ $t('forms.ParentCompanyForm.footer.links.add_link') }}</v-btn>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import Company                     from '@/models/Company'
import FormMixin                   from '@/mixins/FormMixin'
import { required, empty, length } from '@/utils/validation'

export default {
    name: 'FooterForm',
    mixins: [ FormMixin(Company, 'mark_text_field') ],
    data: vm => {
        return {
            rules: {
                optional: [ length(1, 191).or(empty) ],
                required: [ required.and(length(1, 191)) ],
            },
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        addLink() {
            this.value.json.footer.links.push({
                text: '',
                url : '',
            })
        },
        removeLink(index) {
            this.value.json.footer.links.splice(index, 1)
        },
    },
    computed: {
        mark() {
            return this.$lodash.get(this, 'value.json.footer.mark', {})
        },
        text: {
            get() {
                return this.$lodash.get(this, 'value.json.footer.text', '')
            },
            set(value) {
                this.value.json.footer.text = value
            },
        },
        links() {
            return this.$lodash.get(this, 'value.json.footer.links', [])
        },
        allowAddLink() {
            return this.links.length < 5
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    font-size: 14px;
    font-weight: normal;
}
</style>