var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "mb-2 pt-0", attrs: { fluid: "" } }, [
    _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
      _vm._v(_vm._s(_vm.$t("views.companies.parent.Personalize.title")))
    ]),
    _c(
      "div",
      { staticClass: "mb-2 pt-4 px-2 px-md-10" },
      _vm._l(_vm.computedItems, function(item, index) {
        return _c("PersonalizationCard", {
          key: index,
          ref: item.ref,
          refInFor: true,
          staticClass: "mb-2",
          attrs: { title: item.title },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function() {
                  return [
                    _c(item.component, {
                      tag: "component",
                      on: {
                        "update-is-completed": function(value) {
                          return _vm.handleUpdateIsCompleted(item.ref, value)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }