<template>
    <v-card class="py-0 px-1 elevation-0">
        <v-card-title @click="expanded = !expanded" class="card-title">
            <v-icon v-if="expanded" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'" class="primary--text">fas fa-fw fa-minus</v-icon>
            <v-icon v-else :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'" class="primary--text">fas fa-fw fa-plus</v-icon>

            <span class="ml-2 ml-md-6 card-title-text">{{ title }}</span>

            <div class="ml-auto">
                <v-icon :class="isComplete ? 'mb-1 sucesss_green--text' : 'mb-1 table_divider_color--text'" :size="$vuetify.breakpoint.mdAndUp ? 'large' : 'small'">fas fa-fw fa-check-circle</v-icon>
            </div>
        </v-card-title>

        <v-expand-transition>
            <div v-show="expanded">
                <slot name="content"/>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    name: 'CompanyPersonalizationCard',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        expanded: false,
        isComplete: false,
    }),
    methods: {
        setIsComplete(value) {
            this.isComplete = value
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title:hover {
    cursor: pointer;
}

.card-title-text {
    font-size: 16px;
}
</style>