<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <cp-text-field   
                    ref="banner_title_field"
                    :label="$t('forms.ParentCompanyForm.banner.title.label')"
                    type="text"
                    v-model="value.json.banner.title"
                    :rules="rules"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import FormMixin            from '@/mixins/FormMixin'
import { required, length } from '@/utils/validation'
import Company              from '@/models/Company'

export default {
    name: 'RegistrationMessageForm',
    mixins: [ FormMixin(Company, 'banner_title_field') ],
    data: vm => {
        return {
            rules: [ required.and(length(1, 191)) ]
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
    },
}
</script>