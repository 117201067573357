<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="py-0">
                <cp-text-field   
                    ref="official_program_name_field"
                    :label="$t('forms.ParentCompanyForm.official_program_name.label')"
                    type="text"
                    v-model="value.json.official_program_name"
                    :rules="rules"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="py-0">
                <cp-text-field   
                    ref="program_name_field"
                    :label="$t('forms.ParentCompanyForm.program_name.label')"
                    type="text"
                    v-model="value.json.program_name"
                    :rules="rules"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="3" class="py-0">
                <SelectInput
                    ref="company_display_mode_field"
                    :label="$t('forms.ParentCompanyForm.display_mode.label')"
                    :value="initialDisplay"
                    :items="displayModes"
                    @input="displayChanged"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                <v-checkbox
                    ref="company_disable_cashback_field"
                    v-model="value.json.disable_cashback"
                    :label="$t('forms.ParentCompanyForm.disable_cashback.label')"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                <v-checkbox
                    ref="company_hide_points_field"
                    v-model="value.json.hide_points"
                    :label="$t('forms.ParentCompanyForm.hide_points.label')"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row>            
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                <v-checkbox
                    ref="company_hide_cashback_field"
                    v-model="value.json.hide_cashback"
                    :label="$t('forms.ParentCompanyForm.hide_cashback.label')"
                    :disabled="forceHideCashback"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                <v-checkbox
                    ref="company_disable_action_notifications"
                    v-model="value.json.disable_action_notifications"
                    :label="$t('forms.ParentCompanyForm.disable_action_notifications.label')"
                    :disabled="forceDisableActionNotifications"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="12" xs="12" md="6" lg="3" class="pa-4 text-center">
                <v-label>{{ $t('forms.ParentCompanyForm.favicon_url.label') }}</v-label>
                <div><small>{{ $t('forms.ParentCompanyForm.favicon_url.hint') }}</small></div>
                <ImageInput
                    ref="favicon_field"
                    previewMaxHeight="100px"
                    previewMaxWidth="160px"
                    v-model="value.json.favicon_url"
                    :postUpload="updateCompanyJson('favicon_url')"
                    type="update-company-favicon"
                    :maxSizeMB="2"
                    required
                    class="mt-6 mb-4"
                />
            </v-col>

            <v-col cols="12" xs="12" md="6" lg="3" class="pa-4 text-center">
                <v-label>{{ $t('forms.ParentCompanyForm.logo_url.label') }}</v-label>
                <div><small>{{ $t('forms.ParentCompanyForm.logo_url.hint') }}</small></div>
                <ImageInput
                    ref="logo_field"
                    previewMaxHeight="100px"
                    previewMaxWidth="160px"
                    v-model="value.json.logo_url"
                    :postUpload="updateCompanyJson('logo_url')"
                    type="update-company-logo"
                    :maxSizeMB="2"
                    required
                    class="mt-6 mb-4"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="12" lg="6" class="px-lg-4">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="frontend_domain_field"
                            :label="$t('forms.ParentCompanyForm.frontend_domain.label')"
                            :hint="$t('forms.ParentCompanyForm.frontend_domain.hint')"
                            :placeholder="$t('forms.ParentCompanyForm.frontend_domain.placeholder')"
                            required type="text"
                            v-model="value.json.frontend_domain"
                            :rules="rules"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="landing_page_domain_field"
                            :label="$t('forms.ParentCompanyForm.landing_page_domain.label')"
                            :hint="$t('forms.ParentCompanyForm.landing_page_domain.hint')"
                            :placeholder="$t('forms.ParentCompanyForm.landing_page_domain.placeholder')"
                            required type="text"
                            v-model="value.json.landing_page_domain"
                            @keyup.enter="$emit('triggerSubmit')"
                            :rules="rules"
                            disabled
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="admin_domain_field"
                            :label="$t('forms.ParentCompanyForm.admin_domain.label')"
                            :hint="$t('forms.ParentCompanyForm.admin_domain.hint')"
                            :placeholder="$t('forms.ParentCompanyForm.admin_domain.placeholder')"
                            required type="text"
                            v-model="value.json.admin_domain"
                            @keyup.enter="$emit('triggerSubmit')"
                            :rules="rules"
                            disabled
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
            
        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import SelectInput          from '@/shared/components/inputs/SelectInput'
import ImageInput           from '@/components/inputs/ImageInput'
import CompanyJson          from '@/models/CompanyJson'
import FormMixin            from '@/mixins/FormMixin'
import { required, length } from '@/utils/validation'
import Company              from '@/models/Company'

export default {
    name: 'ProgramSettingsForm',
    mixins: [ FormMixin(Company, 'official_program_name_field') ],
    components: {
        SelectInput,
        ImageInput,
    },
    data: vm => {
        return {
            rules: [ required.and(length(1, 191)) ],
            updateJsonLoading: false,
            displayModes: CompanyJson.displayModes(),
            initialDisplay: {},

            faviconPreview: null,
            logoPreview: null,
        }
    },
    created() {
        this.setInitialDisplay()
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        presubmit() {
            // Salva o preview da foto
            this.faviconPreview    = this.$lodash.get(this.localValue, 'json.favicon_url')
            this.logoPreview       = this.$lodash.get(this.localValue, 'json.logo_url')
            this.$lodash.set(this.localValue, 'json.favicon_url', null)
            this.$lodash.set(this.localValue, 'json.logo_url', null)
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.$lodash.set(this.localValue, 'json.favicon_url', this.faviconPreview)
            this.$lodash.set(this.localValue, 'json.logo_url', this.logoPreview)
            this.faviconPreview    = null
            this.logoPreview       = null
            return true
        },
        async triggerFileUploads() {
            await this.$refs.favicon_field.requestFileUpload()
            await this.$refs.logo_field.requestFileUpload()
        },
        setInitialDisplay() {
            this.initialDisplay = CompanyJson.mapDisplay(this.value.json.display_mode)
            this.displayChanged(this.initialDisplay)
        },
        displayChanged(value) {
            this.value.json.display_mode = value
        },
        updateCompanyJson(attributeName) {
            return async (value) => {
                this.value.json[attributeName] = value
                this.$store.commit('company/SET_COMPANY_DATA', this.value.json)
            }
        },
    },
    computed: {
        forceHideCashback() {
            return !!(this.value.json.disable_cashback || this.value.json.hide_points)
        },
        forceDisableActionNotifications() {
            return !this.value.json.custom_emails.enabled && !!this.value.json.hide_cashback
        },
    },
    watch: {
        'value.json.disable_cashback': function (value) {
            if (value) {
                this.value.json.hide_cashback = true
                return
            }

            if (this.value.json.hide_points)
                return

            this.value.json.hide_cashback = false
        },
        'value.json.hide_points': function (value) {
            if (value) {
                this.value.json.hide_cashback = true
                return
            }

            if (this.value.json.disable_cashback)
                return

            this.value.json.hide_cashback = false
        },
        'value.json.hide_cashback': function (value) {
            if (!this.value.json.custom_emails.enabled && value) {
                this.value.json.disable_action_notifications = true
                return
            }

            this.value.json.disable_action_notifications = false
        },
    },
}
</script>