import { render, staticRenderFns } from "./ProgramSettings.vue?vue&type=template&id=cd4a390c&"
import script from "./ProgramSettings.vue?vue&type=script&lang=js&"
export * from "./ProgramSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VAlert,VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cd4a390c')) {
      api.createRecord('cd4a390c', component.options)
    } else {
      api.reload('cd4a390c', component.options)
    }
    module.hot.accept("./ProgramSettings.vue?vue&type=template&id=cd4a390c&", function () {
      api.rerender('cd4a390c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/companies/parent/content/ProgramSettings.vue"
export default component.exports