var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "mark_text_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.footer.mark.text.label"
                  ),
                  type: "text",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.mark.text,
                  callback: function($$v) {
                    _vm.$set(_vm.mark, "text", $$v)
                  },
                  expression: "mark.text"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "mark_url_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.footer.mark.url.label"
                  ),
                  type: "text",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.mark.url,
                  callback: function($$v) {
                    _vm.$set(_vm.mark, "url", $$v)
                  },
                  expression: "mark.url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "text_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.footer.text.label"),
                  type: "text",
                  rules: _vm.rules.optional
                },
                model: {
                  value: _vm.text,
                  callback: function($$v) {
                    _vm.text = $$v
                  },
                  expression: "text"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "pa-0 pt-6 section-title primary--text" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("views.companies.parent.Infos.footer.links.title")
              ) +
              " "
          )
        ]
      ),
      _vm._l(_vm.links, function(link, i) {
        return _c(
          "div",
          { key: i, staticClass: "pt-3" },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass: "py-0",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                  },
                  [
                    _c("cp-text-field", {
                      ref: "link" + i + "_text_field",
                      refInFor: true,
                      attrs: {
                        label: _vm.$t(
                          "forms.ParentCompanyForm.footer.links.text.label"
                        ),
                        type: "text",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: link.text,
                        callback: function($$v) {
                          _vm.$set(link, "text", $$v)
                        },
                        expression: "link.text"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "py-0",
                    attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                  },
                  [
                    _c("cp-text-field", {
                      ref: "link" + i + "_url_field",
                      refInFor: true,
                      attrs: {
                        label: _vm.$t(
                          "forms.ParentCompanyForm.footer.links.url.label"
                        ),
                        type: "text",
                        rules: _vm.rules.required
                      },
                      model: {
                        value: link.url,
                        callback: function($$v) {
                          _vm.$set(link, "url", $$v)
                        },
                        expression: "link.url"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "background error--text mb-3",
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    return _vm.removeLink(i)
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t("forms.ParentCompanyForm.footer.links.remove_link")
                  )
                )
              ]
            ),
            _c("v-divider")
          ],
          1
        )
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "background primary--text",
                  attrs: { disabled: !_vm.allowAddLink, text: "" },
                  on: { click: _vm.addLink }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("forms.ParentCompanyForm.footer.links.add_link")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }