import { render, staticRenderFns } from "./EmailsCustomizationForm.vue?vue&type=template&id=d6f4c480&"
import script from "./EmailsCustomizationForm.vue?vue&type=script&lang=js&"
export * from "./EmailsCustomizationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardSubtitle,VCheckbox,VCol,VDivider,VForm,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d6f4c480')) {
      api.createRecord('d6f4c480', component.options)
    } else {
      api.reload('d6f4c480', component.options)
    }
    module.hot.accept("./EmailsCustomizationForm.vue?vue&type=template&id=d6f4c480&", function () {
      api.rerender('d6f4c480', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/forms/EmailsCustomizationForm.vue"
export default component.exports