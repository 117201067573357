<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row no-gutters>
            <v-col cols="12" xs="12" md="12" class="pa-4 pt-0 text-center">
                <v-label>{{ $t('forms.ParentCompanyForm.background_url.label') }}</v-label>
                <div><small>{{ $t('forms.ParentCompanyForm.background_url.hint') }}</small></div>
                <ImageInput
                    ref="background_field"
                    :previewMaxHeight="$vuetify.breakpoint.mdAndUp ? '300px' : '160px'"
                    :previewMaxWidth="$vuetify.breakpoint.mdAndUp ? '300px' : '160px'"
                    v-model="value.json.background_url"
                    :postUpload="updateCompanyJson('background_url')"
                    type="update-company-background"
                    required
                    class="mt-6 mb-4"
                />
            </v-col>

            <v-col cols="12" xs="12" md="4" class="pt-6 pb-md-6 px-6">
                <ColorPickerInput 
                    ref="font_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.font_color.label')"
                    @input="fontColorChanged"
                    v-model="value.json.font_color"
                    :rules="rules"
                />
            </v-col>
            <v-col cols="12" xs="12" md="4" class="pt-12 pt-md-6 pb-md-6 px-6">
                <ColorPickerInput
                    ref="primary_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.primary_color.label')"
                    @input="primaryColorChanged"
                    v-model="value.json.primary_color"
                    :rules="rules"
                />
            </v-col>
            <v-col cols="12" xs="12" md="4" class="pt-12 pt-md-6 pb-6 px-6">
                <ColorPickerInput
                    ref="background_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.background_color.label')"
                    @input="backgroundColorChanged"
                    v-model="value.json.background_color"
                    :rules="rules"
                />
            </v-col>
        </v-row>
            
        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import Company              from '@/models/Company'
import FormMixin            from '@/mixins/FormMixin'
import { required, length } from '@/utils/validation'
import CompanyJson          from '@/models/CompanyJson'
import ImageInput           from '@/components/inputs/ImageInput'
import ColorPickerInput     from '@/components/inputs/ColorPickerInput'

export default {
    name: 'GeneralLayoutForm',
    mixins: [ FormMixin(Company, '') ],
    components: {
        ImageInput,
        ColorPickerInput,
    },
    data: vm => {
        return {
            rules: [ required.and(length(1, 191)) ],
            updateJsonLoading: false,

            backgroundPreview: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        presubmit() {
            // Salva o preview da foto
            this.backgroundPreview = this.$lodash.get(this.localValue, 'json.background_url')
            this.$lodash.set(this.localValue, 'json.background_url', null)
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.$lodash.set(this.localValue, 'json.background_url', this.backgroundPreview)
            this.backgroundPreview = null
            return true
        },

        async triggerFileUploads() {
            await this.$refs.background_field.requestFileUpload()
        },

        fontColorChanged(value) {
            this.value.json.font_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        primaryColorChanged(value) {
            this.value.json.primary_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        backgroundColorChanged(value) {
            this.value.json.background_color = typeof(value) == 'string' ? value.substring(0, 7) :  value.hex.substring(0, 7)
        },
        updateCompanyJson(attributeName) {
            return async (value) => {
                this.value.json[attributeName] = value
                this.$store.commit('company/SET_COMPANY_DATA', this.value.json)
            }
        },
    },
}
</script>