var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "12" }
            },
            [
              _c("cp-text-field", {
                ref: "banner_title_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.banner.title.label"),
                  type: "text",
                  rules: _vm.rules
                },
                model: {
                  value: _vm.value.json.banner.title,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json.banner, "title", $$v)
                  },
                  expression: "value.json.banner.title"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }