var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "12", lg: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "official_program_name_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.official_program_name.label"
                  ),
                  type: "text",
                  rules: _vm.rules
                },
                model: {
                  value: _vm.value.json.official_program_name,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "official_program_name", $$v)
                  },
                  expression: "value.json.official_program_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "3" }
            },
            [
              _c("cp-text-field", {
                ref: "program_name_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.program_name.label"),
                  type: "text",
                  rules: _vm.rules
                },
                model: {
                  value: _vm.value.json.program_name,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "program_name", $$v)
                  },
                  expression: "value.json.program_name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "3" }
            },
            [
              _c("SelectInput", {
                ref: "company_display_mode_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.display_mode.label"),
                  value: _vm.initialDisplay,
                  items: _vm.displayModes
                },
                on: { input: _vm.displayChanged }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "6" }
            },
            [
              _c("v-checkbox", {
                ref: "company_disable_cashback_field",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.disable_cashback.label"
                  )
                },
                model: {
                  value: _vm.value.json.disable_cashback,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "disable_cashback", $$v)
                  },
                  expression: "value.json.disable_cashback"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "6" }
            },
            [
              _c("v-checkbox", {
                ref: "company_hide_points_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.hide_points.label")
                },
                model: {
                  value: _vm.value.json.hide_points,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "hide_points", $$v)
                  },
                  expression: "value.json.hide_points"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "6" }
            },
            [
              _c("v-checkbox", {
                ref: "company_hide_cashback_field",
                attrs: {
                  label: _vm.$t("forms.ParentCompanyForm.hide_cashback.label"),
                  disabled: _vm.forceHideCashback
                },
                model: {
                  value: _vm.value.json.hide_cashback,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "hide_cashback", $$v)
                  },
                  expression: "value.json.hide_cashback"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6", lg: "6" }
            },
            [
              _c("v-checkbox", {
                ref: "company_disable_action_notifications",
                attrs: {
                  label: _vm.$t(
                    "forms.ParentCompanyForm.disable_action_notifications.label"
                  ),
                  disabled: _vm.forceDisableActionNotifications
                },
                model: {
                  value: _vm.value.json.disable_action_notifications,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.value.json,
                      "disable_action_notifications",
                      $$v
                    )
                  },
                  expression: "value.json.disable_action_notifications"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-4 text-center",
              attrs: { cols: "12", xs: "12", md: "6", lg: "3" }
            },
            [
              _c("v-label", [
                _vm._v(
                  _vm._s(_vm.$t("forms.ParentCompanyForm.favicon_url.label"))
                )
              ]),
              _c("div", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("forms.ParentCompanyForm.favicon_url.hint"))
                  )
                ])
              ]),
              _c("ImageInput", {
                ref: "favicon_field",
                staticClass: "mt-6 mb-4",
                attrs: {
                  previewMaxHeight: "100px",
                  previewMaxWidth: "160px",
                  postUpload: _vm.updateCompanyJson("favicon_url"),
                  type: "update-company-favicon",
                  maxSizeMB: 2,
                  required: ""
                },
                model: {
                  value: _vm.value.json.favicon_url,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "favicon_url", $$v)
                  },
                  expression: "value.json.favicon_url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-4 text-center",
              attrs: { cols: "12", xs: "12", md: "6", lg: "3" }
            },
            [
              _c("v-label", [
                _vm._v(_vm._s(_vm.$t("forms.ParentCompanyForm.logo_url.label")))
              ]),
              _c("div", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.$t("forms.ParentCompanyForm.logo_url.hint"))
                  )
                ])
              ]),
              _c("ImageInput", {
                ref: "logo_field",
                staticClass: "mt-6 mb-4",
                attrs: {
                  previewMaxHeight: "100px",
                  previewMaxWidth: "160px",
                  postUpload: _vm.updateCompanyJson("logo_url"),
                  type: "update-company-logo",
                  maxSizeMB: 2,
                  required: ""
                },
                model: {
                  value: _vm.value.json.logo_url,
                  callback: function($$v) {
                    _vm.$set(_vm.value.json, "logo_url", $$v)
                  },
                  expression: "value.json.logo_url"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-lg-4",
              attrs: { cols: "12", xs: "12", sm: "12", md: "12", lg: "6" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "frontend_domain_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ParentCompanyForm.frontend_domain.label"
                          ),
                          hint: _vm.$t(
                            "forms.ParentCompanyForm.frontend_domain.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.ParentCompanyForm.frontend_domain.placeholder"
                          ),
                          required: "",
                          type: "text",
                          rules: _vm.rules,
                          disabled: ""
                        },
                        model: {
                          value: _vm.value.json.frontend_domain,
                          callback: function($$v) {
                            _vm.$set(_vm.value.json, "frontend_domain", $$v)
                          },
                          expression: "value.json.frontend_domain"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "landing_page_domain_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ParentCompanyForm.landing_page_domain.label"
                          ),
                          hint: _vm.$t(
                            "forms.ParentCompanyForm.landing_page_domain.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.ParentCompanyForm.landing_page_domain.placeholder"
                          ),
                          required: "",
                          type: "text",
                          rules: _vm.rules,
                          disabled: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.json.landing_page_domain,
                          callback: function($$v) {
                            _vm.$set(_vm.value.json, "landing_page_domain", $$v)
                          },
                          expression: "value.json.landing_page_domain"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "admin_domain_field",
                        attrs: {
                          label: _vm.$t(
                            "forms.ParentCompanyForm.admin_domain.label"
                          ),
                          hint: _vm.$t(
                            "forms.ParentCompanyForm.admin_domain.hint"
                          ),
                          placeholder: _vm.$t(
                            "forms.ParentCompanyForm.admin_domain.placeholder"
                          ),
                          required: "",
                          type: "text",
                          rules: _vm.rules,
                          disabled: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.json.admin_domain,
                          callback: function($$v) {
                            _vm.$set(_vm.value.json, "admin_domain", $$v)
                          },
                          expression: "value.json.admin_domain"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }