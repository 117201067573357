var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c("p", { staticClass: "grey--text" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("views.companies.parent.Infos.custom_emails.description")
            ) +
            " "
        )
      ]),
      _c("v-checkbox", {
        ref: "email_customization_enabled_field",
        attrs: {
          label: _vm.$t(
            "forms.ParentCompanyForm.emails_customization.enabled.label"
          )
        },
        model: {
          value: _vm.value.json.custom_emails.enabled,
          callback: function($$v) {
            _vm.$set(_vm.value.json.custom_emails, "enabled", $$v)
          },
          expression: "value.json.custom_emails.enabled"
        }
      }),
      _vm.value.json.custom_emails.enabled
        ? _c(
            "div",
            { staticClass: "mb-6" },
            [
              _vm._l(_vm.emailFields, function(emailField, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "v-card-subtitle",
                      { staticClass: "pa-0 pt-6 primary--text" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "views.companies.parent.Infos.custom_emails." +
                                  emailField +
                                  ".title"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("p", { staticClass: "grey--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.companies.parent.Infos.custom_emails." +
                                        emailField +
                                        ".description"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("cp-text-field", {
                              ref:
                                "emails_customization_" +
                                emailField +
                                "_title_field",
                              refInFor: true,
                              attrs: {
                                label: _vm.$t(
                                  "forms.ParentCompanyForm.emails_customization." +
                                    emailField +
                                    ".title.label"
                                ),
                                type: "text"
                              },
                              model: {
                                value:
                                  _vm.value.json.custom_emails[emailField]
                                    .title,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.value.json.custom_emails[emailField],
                                    "title",
                                    $$v
                                  )
                                },
                                expression:
                                  "value.json.custom_emails[emailField].title"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("div", { staticClass: "mb-3" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "mr-0 mr-lg-4 caption",
                                  attrs: {
                                    for:
                                      "emails_customization_" +
                                      emailField +
                                      "_body_field"
                                  }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "forms.ParentCompanyForm.emails_customization." +
                                            emailField +
                                            ".body.label"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              [
                                _c("TiptapVuetify", {
                                  ref:
                                    "emails_customization_" +
                                    emailField +
                                    "_body_field",
                                  refInFor: true,
                                  staticClass: "mt-2 mt-lg-0 w-100",
                                  attrs: {
                                    value:
                                      _vm.value.json.custom_emails[emailField]
                                        .body,
                                    extensions: _vm.tiptapExtensions,
                                    "card-props": {
                                      flat: true,
                                      outlined: true
                                    },
                                    "editor-props": {},
                                    "elevation-0": ""
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.onInput(
                                        "custom_emails." + emailField + ".body",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c("v-divider")
            ],
            2
          )
        : _vm._e(),
      _vm._t("default"),
      _vm._t("submit"),
      _c("div", { staticClass: "clear-fix" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }