<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <cp-text-field   
                    ref="faq_title_field"
                    :label="$t('forms.ParentCompanyForm.faq.title.label')"
                    type="text"
                    v-model="value.json.faq.title"
                    :rules="rules"
                />
            </v-col>
        </v-row>

        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.faq.items.title') }}
        </v-card-title>

        <v-row
            v-for="(item, index) in value.json.faq.items"
            :key="index"
        >
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-textarea   
                    :ref="`faq_items_question_${index}_field`"
                    :label="$t('forms.ParentCompanyForm.faq.items.question.label', { index: Number(index) + 1 })"
                    type="text"
                    rows="4"
                    v-model="value.json.faq.items[index].question"
                    :rules="rules"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-textarea   
                    :ref="`faq_items_answer_${index}_field`"
                    :label="$t('forms.ParentCompanyForm.faq.items.answer.label', { index: Number(index) + 1 })"
                    type="text"
                    rows="4"
                    v-model="value.json.faq.items[index].answer"
                    :rules="rules"
                />
            </v-col>
            <v-col v-if="$vuetify.breakpoint.smAndDown && value.json.faq.items && value.json.faq.items.length && Number(index) < value.json.faq.items.length - 1" cols="12" class="pb-6">
                <v-divider></v-divider>  
            </v-col>      
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import Company              from '@/models/Company'
import FormMixin            from '@/mixins/FormMixin'
import { empty, length } from '@/utils/validation'

export default {
    name: 'FaqForm',
    mixins: [ FormMixin(Company, 'faq_title_field') ],
    data: vm => {
        return {
            rules: [ empty.or(length(1, 191)) ]
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        }
    },
}
</script>

<style scoped lang="scss">
.section-title {
    font-size: 14px;
    font-weight: normal;
}
</style>