<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" class="pt-0 pb-6">
                <v-switch
                    :label="$t('forms.ParentCompanyForm.legal.custom_terms_enable.label')"
                    v-model="is_custom_terms_enabled"
                ></v-switch>

                <div v-if="is_custom_terms_enabled">
                    <div class="mb-3">
                        <label for="custom_terms_html_field" class="mr-0 mr-lg-4 primary--text">{{ $t('forms.ParentCompanyForm.legal.custom_terms_html.label') }}</label>
                    </div>
                    <div>
                        <TiptapVuetify
                            ref="custom_terms_html_field"
                            :value="value.json.legal.custom_terms_html"
                            @input="onInput('custom_terms_html', $event)"
                            class="mt-2 mt-lg-0 w-100"
                            :extensions="tiptapExtensions"
                            :card-props="{ flat: true, outlined: true }"
                            :editor-props="{  }"
                            elevation-0
                        />
                        <small>{{ $t('forms.ParentCompanyForm.legal.custom_terms_html.hint') }}</small>
                    </div>

                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-6" v-if="!is_custom_terms_enabled">
                <div class="d-flex flex-column flex-lg-row align-lg-center">
                    <label for="privacy_policy_url_field" class="mr-0 mr-lg-4 primary--text">{{ $t('forms.ParentCompanyForm.legal.privacy_policy_url.label') }}</label>
                    <FileInput 
                        ref="privacy_policy_url_field"
                        :value="value.json.legal.privacy_policy_url"
                        @input="onInput('privacy_policy_url', $event)"
                        :postUpload="updateCompanyJson('privacy_policy_url')"
                        type="update-company-privacy-policy"
                        :maxSizeMB="4"
                        class="mt-2 mt-lg-0"
                    />
                </div>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="pt-4 pb-6 pt-md-0" v-if="!is_custom_terms_enabled">
                <div class="d-flex flex-column flex-lg-row align-lg-center">
                    <label for="legal_terms_url_field" class="mr-0 mr-lg-4 primary--text">{{ $t('forms.ParentCompanyForm.legal.terms_url.label') }}</label>
                    <FileInput 
                        ref="legal_terms_url_field"
                        :postUpload="updateCompanyJson('terms_url')"
                        :value="value.json.legal.terms_url"
                        @input="onInput('terms_url', $event)"
                        type="update-company-terms"
                        :maxSizeMB="4"
                        class="mt-2 mt-lg-0"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-6">
                <div class="d-flex flex-column flex-lg-row align-lg-center">
                    <label for="cookie_policy_url_field" class="mr-0 mr-lg-4 primary--text">{{ $t('forms.ParentCompanyForm.legal.cookie_policy_url.label') }}</label>
                    <FileInput 
                        ref="cookie_policy_url_field"
                        :value="value.json.legal.cookie_policy_url"
                        @input="onInput('cookie_policy_url', $event)"
                        :postUpload="updateCompanyJson('cookie_policy_url')"
                        type="update-company-cookie-policy"
                        :maxSizeMB="4"
                        class="mt-2 mt-lg-0"
                    />
                </div>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import FileInput from '@/components/inputs/FileInput'
import FormMixin from '@/mixins/FormMixin'
import Company from '@/models/Company'
import { TiptapVuetify, Link } from 'tiptap-vuetify'

const TargetBlankLink = [Link, {
    options: {
        target: '_blank',
    },
}]

export default {
    name: 'TermsForm',
    mixins: [ FormMixin(Company, 'legal_privacy_policy_url_field') ],
    components: { FileInput, TiptapVuetify },
    data: vm => ({
        is_custom_terms_enabled: !!vm.$lodash.get(vm, 'value.json.legal.custom_terms_html'),
        tiptapExtensions: [ TargetBlankLink ],
    }),
    methods: {
        validate() {
            return this.value.json.legal.privacy_policy_url && this.value.json.legal.terms_url
        },
        updateCompanyJson(attributeName) {
            return async (value) => {
                await this.value.update(['json'])
                    .catch(this.errorHandler)

                this.$emit('cloneCompany')
            }
        },
        onInput(attributeName, value) {
            this.$set(this.value.json.legal, attributeName, value)
        },
    },
    watch: {
        is_custom_terms_enabled(newValue) {
            if (!newValue) {
                this.onInput('custom_terms_html', '')
            }
        },
    }
}
</script>