<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                <cp-text-field   
                    ref="home_title_field"
                    :label="$t('forms.ParentCompanyForm.home.title.label')"
                    type="text"
                    v-model="value.json.home.title"
                    :rules="rules.required"
                />
            </v-col>
        </v-row>

        <!-- Seção da navbar -->
        <v-card-title class="pa-0 section-title primary--text">
            {{ $t('views.companies.parent.Infos.home.navbar.title') }}
        </v-card-title>

        <v-row no-gutters class="justify-center">
            <v-col cols="12" xs="12" sm="12" md="4" class="pt-6 pb-md-6 px-6">
                <ColorPickerInput
                    ref="landing_page_navbar_font_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.landing_page.font_color.label')"
                    @input="navbarFontColorChanged"
                    v-model="value.json.landing_page_colors.nav_font_color"
                    :rules="rules.optional"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" class="pt-12 pt-md-6 pb-6 px-6">
                <ColorPickerInput
                    ref="landing_page_background_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.landing_page.background_color.label')"
                    @input="navbarBackgroundColorChanged"
                    v-model="value.json.landing_page_colors.nav_background_color"
                    :rules="rules.optional"
                />
            </v-col>
        </v-row>

        <!-- Seção principal da home -->
        <v-card-title class="pa-0 section-title primary--text">
            {{ $t('views.companies.parent.Infos.home.hero.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="home_hero_title_field"
                    :label="$t('forms.ParentCompanyForm.home.hero.title.label')"
                    type="text"
                    v-model="value.json.home.hero.title"
                    :rules="rules.optional"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="home_hero_subtitle_field"
                    :label="$t('forms.ParentCompanyForm.home.hero.subtitle.label')"
                    type="text"
                    v-model="value.json.home.hero.subtitle"
                    :rules="rules.optional"
                />
            </v-col>
            <v-col cols="12" class="py-0">
                <cp-textarea   
                    ref="home_hero_description_field"
                    :label="$t('forms.ParentCompanyForm.home.hero.description.label')"
                    type="text"
                    rows="4"
                    v-model="value.json.home.hero.description"
                    :rules="rules.optional"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="text-center py-0">
                <v-label>{{ $t('forms.ParentCompanyForm.home.hero.background_url.label') }}</v-label>
                <div><small>{{ $t('forms.ParentCompanyForm.home.hero.background_url.hint') }}</small></div>
                <ImageInput
                    ref="home_hero_background_url_field"
                    :label="$t('forms.ParentCompanyForm.home.hero.background_url.label')"
                    :hint="$t('forms.ParentCompanyForm.home.hero.background_url.hint')"
                    :placeholder="$t('forms.ParentCompanyForm.home.hero.background_url.placeholder')"
                    previewMaxHeight="300px"
                    previewMaxWidth="300px"
                    v-model="value.json.home.hero.background_url"
                    :postUpload="updateCompanyJson('json.home.hero.background_url')"
                    type="update-company-home-background"
                    :rules="rules.required"
                    required
                    class="mt-6 mb-4"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="pt-8 pb-0 pt-md-0">
                <div><v-label>{{ $t('forms.ParentCompanyForm.home.hero.height.label') }}</v-label></div>
                <small>{{ $t('forms.ParentCompanyForm.home.hero.height.hint') }}</small>
                <v-radio-group v-model="value.json.home.hero.height" :mandatory="true">
                    <v-radio :label="$t('forms.ParentCompanyForm.home.hero.height.options.small')" value="small"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.home.hero.height.options.medium')" value="medium"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.home.hero.height.options.large')" value="large"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.home.hero.height.options.fullheight')" value="fullheight"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <!-- Seção features da home -->
        <v-card-title class="pa-0 section-title primary--text">
            {{ $t('views.companies.parent.Infos.home.features.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="home_features_title_field"
                    :label="$t('forms.ParentCompanyForm.home.features.title.label')"
                    type="text"
                    v-model="value.json.home.features.title"
                    :rules="rules.required"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <cp-text-field   
                    ref="home_features_subtitle_field"
                    :label="$t('forms.ParentCompanyForm.home.features.subtitle.label')"
                    type="text"
                    v-model="value.json.home.features.subtitle"
                    :rules="rules.required"
                />
            </v-col>
        </v-row>

        <!-- Seção principal do "como funciona" -->
        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.how_it_works.hero.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field   
                    ref="how_it_works_hero_title_field"
                    :label="$t('forms.ParentCompanyForm.how_it_works.hero.title.label')"
                    type="text"
                    v-model="value.json.how_it_works.hero.title"
                    :rules="rules.required"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="text-center py-0">
                <v-label>{{ $t('forms.ParentCompanyForm.how_it_works.hero.background_url.label') }}</v-label>
                <div><small>{{ $t('forms.ParentCompanyForm.how_it_works.hero.background_url.hint') }}</small></div>
                <ImageInput
                    ref="how_it_works_hero_background_url_field"
                    :label="$t('forms.ParentCompanyForm.how_it_works.hero.background_url.label')"
                    :hint="$t('forms.ParentCompanyForm.how_it_works.hero.background_url.hint')"
                    :placeholder="$t('forms.ParentCompanyForm.how_it_works.hero.background_url.placeholder')"
                    previewMaxHeight="300px"
                    previewMaxWidth="300px"
                    v-model="value.json.how_it_works.hero.background_url"
                    :postUpload="updateCompanyJson('json.how_it_works.hero.background_url')"
                    type="update-company-how-it-works-background"
                    :rules="rules.required"
                    required
                    class="mt-6 mb-4"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" class="pt-8 pb-0 pt-md-0">
                <div><v-label>{{ $t('forms.ParentCompanyForm.how_it_works.hero.height.label') }}</v-label></div>
                <small>{{ $t('forms.ParentCompanyForm.how_it_works.hero.height.hint') }}</small>
                <v-radio-group v-model="value.json.how_it_works.hero.height" :mandatory="true">
                    <v-radio :label="$t('forms.ParentCompanyForm.how_it_works.hero.height.options.small')" value="small"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.how_it_works.hero.height.options.medium')" value="medium"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.how_it_works.hero.height.options.large')" value="large"></v-radio>
                    <v-radio :label="$t('forms.ParentCompanyForm.how_it_works.hero.height.options.fullheight')" value="fullheight"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <!-- Seção features do "como funciona" -->
        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.how_it_works.features.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" class="py-0">
                <cp-textarea   
                    ref="how_it_works_features_description_field"
                    :label="$t('forms.ParentCompanyForm.how_it_works.features.description.label')"
                    type="text"
                    rows="4"
                    v-model="value.json.how_it_works.features.description"
                    :rules="rules.required_textarea"
                />
            </v-col>
        </v-row>

        <v-row no-gutters class="justify-center">
            <v-col cols="12" xs="12" sm="12" md="4" class="pt-6 pb-md-6 px-6">
                <ColorPickerInput
                    ref="how_it_works_features_font_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.how_it_works.features.font_color.label')"
                    @input="cardFontColorChanged"
                    v-model="value.json.landing_page_colors.card_font_color"
                    :rules="rules.optional"
                />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" class="pt-12 pt-md-6 pb-6 px-6">
                <ColorPickerInput
                    ref="how_it_works_features_background_color_field"
                    width="200px"
                    class="mx-auto"
                    mode="hexa"
                    :label="$t('forms.ParentCompanyForm.how_it_works.features.background_color.label')"
                    @input="cardBackgroundColorChanged"
                    v-model="value.json.landing_page_colors.card_background_color"
                    :rules="rules.optional"
                />
            </v-col>
        </v-row>

        <!-- Seção "descrição" -->
        <v-card-title class="pa-0 pt-6 section-title primary--text">
            {{ $t('views.companies.parent.Infos.description.title') }}
        </v-card-title>

        <v-row>
            <v-col cols="12" xs="12" sm="12" md="8" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field   
                            ref="description_title_field"
                            :label="$t('forms.ParentCompanyForm.description.title.label')"
                            type="text"
                            v-model="value.json.description.title"
                            :rules="rules.optional"
                        />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <cp-textarea   
                            ref="description_description_field"
                            :label="$t('forms.ParentCompanyForm.description.description.label')"
                            type="text"
                            v-model="value.json.description.description"
                            :rules="rules.optional_textarea"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                <v-row no-gutters>
                    <v-col cols="12" class="pt-6 pb-6 px-6">
                        <ColorPickerInput
                            ref="description_font_color_field"
                            width="200px"
                            class="mx-auto"
                            mode="hexa"
                            :label="$t('forms.ParentCompanyForm.description.font_color.label')"
                            @input="descriptionFontColorChanged"
                            v-model="value.json.landing_page_colors.title_color"
                            :rules="rules.optional"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

        <div class="clear-fix"></div>
    </v-form>
</template>

<script>
import ColorPickerInput from '@/components/inputs/ColorPickerInput'
import ImageInput       from '@/components/inputs/ImageInput'
import FormMixin        from '@/mixins/FormMixin'
import Company          from '@/models/Company'
import { required, length, empty, string } from '@/utils/validation'

export default {
    name: 'LandingPageLayoutForm',
    mixins: [ FormMixin(Company, 'home_title_field') ],
    components: {
        ImageInput,
        ColorPickerInput,
    },
    data: vm => {
        return {
            rules: {
                required         : [required.and(length(1, 191))],
                optional         : [empty.or(length(1, 191))],
                required_textarea: [required.and(length(1, 500))],
                optional_textarea: [empty.or(length(1, 500))],
            },
            HomeHerobackgroundPreview      : null,
            HowItWorksHerobackgroundPreview: null,
        }
    },
    methods: {
        validate() {
            return this.$refs.form.validate()
        },
        presubmit() {
            // Salva o preview da foto
            this.HomeHerobackgroundPreview       = this.$lodash.get(this.localValue, 'json.home.hero.background_url')
            this.HowItWorksHerobackgroundPreview = this.$lodash.get(this.localValue, 'json.how_it_works.hero.background_url')
            this.$set(this.localValue, 'json.home.hero.background_url',         null)
            this.$set(this.localValue, 'json.how_it_works.hero.background_url', null)
            return true
        },
        postsubmit() {
            // Recupera o preview da foto
            this.$set(this.localValue, 'json.home.hero.background_url',         this.HomeHerobackgroundPreview)
            this.$set(this.localValue, 'json.how_it_works.hero.background_url', this.HowItWorksHerobackgroundPreview)
            this.HomeHerobackgroundPreview       = null
            this.HowItWorksHerobackgroundPreview = null
            return true
        },
        navbarFontColorChanged(value) {
            this.value.json.landing_page_colors.nav_font_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        navbarBackgroundColorChanged(value) {
            this.value.json.landing_page_colors.nav_background_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        cardFontColorChanged(value) {
            this.value.json.landing_page_colors.card_font_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        cardBackgroundColorChanged(value) {
            this.value.json.landing_page_colors.card_background_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        descriptionFontColorChanged(value) {
            this.value.json.landing_page_colors.title_color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        async triggerFileUploads() {
            await this.$refs.home_hero_background_url_field.requestFileUpload()
            await this.$refs.how_it_works_hero_background_url_field.requestFileUpload()
        },
        updateCompanyJson(attributeName) {
            return async (value) => {
                this.value.json[attributeName] = value
                this.$store.commit('company/SET_COMPANY_DATA', this.value.json)
            }
        },
    },
    watch: {
        isFormValid(value) {
            this.$emit('update:isFormValid', value)
        },
    },
}
</script>

<style scoped lang="scss">
.section-title {
    font-size: 14px;
    font-weight: normal;
}
</style>